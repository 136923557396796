import React, { useContext } from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import pageContextProvider from '@helpers/pageContextProvider'
import Section from '@components/Section'
import { InlineWidget } from "react-calendly";
import { Text} from 'theme-ui'
import Calendar from "@ericz1803/react-google-calendar"



const API_KEY = "AIzaSyAlBKp29AfW7FvUqvDP0gVVkolnowYfcVw";
let calendars = [
  {calendarId: "jordana@admina.com.br"},  
];



let styles = {
  //you can use object styles (no import required)
  calendar: {
    borderWidth: "8px", //make outer edge of calendar thicker
    backgroundColor: "white"
  },

  //you can also use emotion's string styles
  today: `
   /* highlight today by making the text red and giving it a red border */
    color: red;
    border: 1px solid red;
  `
}



export default props => {

  const context = useContext(pageContextProvider)

  console.log (context)

//  const { services, mobileMenu, darkMode } = context.pageContext

  return (
    <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Agenda do Admina"
          subheader=''
        />
        <Divider />
        
        <Section title=''>
          <Text variant='h2'>
              Calendário de aulões
          </Text>
          <Text variant='p'>
              Encontre abaixo a nossa agenda de aulões
          </Text>

          {/* <InlineWidget url="https://calendly.com/admina/mentoria?month=2022-04" /> */}
          <Calendar apiKey={API_KEY} calendars={calendars} showFooter={false} language='PT' styles={styles} />
        </Section>

        <Divider />

        

      </Main>
{/*       <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar> */}  
    </Stack>
  </Layout>
  )
}